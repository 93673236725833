<template lang="pug">
    .info-banner()
        span.icon ⚠️
        span.text-banner-info(v-if="loadingReactivate == false" ) {{ text }}
        span.text-banner-info(v-if="loadingReactivate == true") {{confirmText}}
        button.reactivate-button(v-if="loadingReactivate == false" @click.stop="reactivateStore") Reactivate Store
    </template>
      
    <script>
    import moment from "moment";
    
    export default {
        props: {
            text: String,
        },
        data() {
            return {
                isOpen: false,
                loadingReactivate: false,
                confirmText: "Reactivation in progress... please reload the page in 2 - 5 min."

            };
        },
        methods: {
   
            reactivateStore() {
                // Insert your store reactivation logic here.
                // For example, you might call an API endpoint to update the store status:
                // this.$axios.post('/api/reactivate', { storeId: this.storeId })
                //   .then(response => { /* handle success */ })
                //   .catch(err => { /* handle error */ });
                console.log("Reactivating store...");
                this.loadingReactivate = true;
                this.$store.dispatch("userdata/reactivateStore").then(_ => {
 

       

                })

            }
        },
        mounted() {
    
        },
        beforeDestroy() {
    
        },
        
    
    };
    </script>
    
    <style lang="scss" scoped>
    .info-banner {
        display: flex;
        align-items: center;
        background-color: $danger;
        color: white;
        padding: 10px;
        border-radius: 6px;
        margin-bottom: 10px;
    }
    .info-banner:hover {
      cursor: pointer;
      /* Add any other visual feedback for hover state */
    }
    .icon {
        margin-right: 15px;
    }
    
    .text-banner-info {
        font-weight: $normal-Font-Weight;
        font-size: $medium-Font-Size;
    }

    .reactivate-button {
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9rem;
}
    </style>